<template>
  <div>
    <el-container>
      <el-aside width="8rem" style="height: 100vh;background-color: #1C2C3C">
        <div class="flex-def flex-zCenter flex-cCenter"
             style="margin-bottom: .5rem;border-bottom: 1px solid #000000;height: 3.5rem;width: 100%">
          <div @click="$router.push(vuex_home_page)" class="y-pointer">
            <img v-if="vuex_global_set.site_logo" :src="vuex_global_set.site_logo" style="height: 2rem;width: 2rem;">
            <span v-else class="y-desc">logo</span>
          </div>
        </div>
        <el-menu
            :router="true"
            :uniqueOpened="true"
            :default-active="path"
            class="el-menu-vertical"
            background-color="#1C2C3C"
            text-color="#ffffff"
            active-text-color="#ffd04b"
            style="border: unset"
        >
          <el-menu-item v-for="(item,key) in vuex_menu" :key="key" :index="item.path">
            <template #title>
              <div class="flex-def flex-zCenter flex-cCenter">
                <i style="font-weight: 600" :class="item.icon"></i>
                <span style="font-weight: 600">{{ item.title }}</span>
              </div>
            </template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header style="padding: 0;height: 3.5rem"><SimpleHeader></SimpleHeader></el-header>
        <el-main style="padding: 0;">
          <YMainBody v-if="!custom">
            <slot></slot>
          </YMainBody>
          <div v-else><slot></slot></div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import SimpleHeader from "@/components/layouts/SimpleHeader";
import YMainBody from "@/components/layouts/YMainBody";

export default {
  name: "SimpleLayout",
  components: {YMainBody, SimpleHeader},
  props:{
    custom:{
      type:Boolean,
      default:false,
    }
  },
  data(){
    return{
      path: "",
    }
  },
  methods:{
    onRouteChanged() {
      let that = this;
      that.path = this.$route.matched[1].path;
    },
  },
  created() {
    this.onRouteChanged()
  },
  watch: {
    '$route': 'onRouteChanged'
  }
}
</script>

<style scoped>

</style>
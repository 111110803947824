<template>
  <el-container>
    <el-header class="y-top-header">
      <div class="y-top-header-content flex-def flex-cCenter flex-zBetween">
        <div>
          <div v-show="!$route.path.includes('/shop')" @click="$router.push(vuex_home_page)" class="y-pointer">
            <img :src="vuex_global_set.site_logo" style="height: 2rem;width: 2rem;">
          </div>
        </div>
        <div class="flex-def flex-cCenter">
          <el-button
              v-if="vuex_account.is_founder && !$route.path.includes('/founder')"
              @click="$u.toFounder"
              style="margin-right: 2rem"
              type="text"
          >
            站点管理
          </el-button>
          <el-dropdown @command="topTap">
            <el-button type="text" style="color: #606266">
              <span>{{ vuex_account.username }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-if="$route.path !== '/account/info'" command="account">我的账号</el-dropdown-item>
                <el-dropdown-item command="logout">退出登陆</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </el-header>
  </el-container>
</template>

<script>

import {accountLogout} from "@/common/util";

export default {
  name: "SimpleHeader",
  methods: {
    topTap(e) {
      this[e] && this[e]();
    },
    account() {
      this.$router.push({name:"account-info"})
    },
    logout(){
      accountLogout();
    },
  }
}
</script>

<style scoped>
.y-top-header {
  background-color: #FFFFFF;
  border-bottom: 1px solid #ededed;
}

.y-top-header-content {
  max-width: 1200px;
  margin: auto;
  height: 100%;
}

</style>
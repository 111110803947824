<template>
   <YLayoutBodyMenu :menu="[
       {title:'等级设置',path:'/shop/plugin/decorate-level/list'},
       {title:'用户管理',path:'/shop/plugin/decorate-level/manage'},
   ]">
     <router-view/>
   </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>